import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7094e650"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"0px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pinComponent = _resolveComponent("pinComponent")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      gutter: 10,
      style: {"text-align":"center","height":"10px"},
      type: "flex",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(7)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(8)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(9)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(10)
            }, null, 8, ["status"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 10,
      style: {"text-align":"center","height":"10px"},
      type: "flex",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(4)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(5)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(6)
            }, null, 8, ["status"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 10,
      style: {"text-align":"center","height":"10px"},
      type: "flex",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(3)
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(2)
            }, null, 8, ["status"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 10,
      style: {"text-align":"center","height":"20px"},
      type: "flex",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_pinComponent, {
              status: _ctx.pinStatus(1)
            }, null, 8, ["status"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}