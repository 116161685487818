const JWT = "jwt";

const init = localStorage[JWT];

// eslint-disable-next-line
const plugin = (store: any) => {
  // eslint-disable-next-line
  store.subscribe((mutation: any) => {
    switch (mutation.type) {
      case "auth/setToken":
        localStorage[JWT] = mutation.payload;
        break;
      case "auth/resetToken":
        localStorage.removeItem(JWT);
        break;
    }
  });
};

export default plugin;
export { init };
