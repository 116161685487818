import { createApp } from "vue";
// eslint-disable-next-line
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementPlus from "element-plus";
import "element-plus/dist/index.css";
import ptBr from "element-plus/es/locale/lang/pt-br";

import { onAuthStateChanged, getAuth, signInAnonymously } from "firebase/auth";

const auth = getAuth();
signInAnonymously(auth).then(function (user) {

});
onAuthStateChanged(auth, (user) => {

  const app = createApp(App)// eslint-disable-line
    .use(store)
    .use(router)
    .use(elementPlus, { locale: ptBr })
    .mount("#app");

  // TODO: fetch user data
  if (user) store.dispatch("auth/login", { ...user });
  else store.dispatch("auth/logout");
});
