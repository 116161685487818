import { init } from "@/store/plugins/localstorage";
import { FirebaseUser, getAuth } from "@/helpers/firebase";

export interface AuthState {
  token: string;
  user: object;
}

const state = {
  token: init,
  user: null,
};

const mutations = {
  setToken(state: AuthState, token: string) {
    state.token = token;
  },
  resetToken(state: AuthState) {
    state.token = "";
  },
  setUser(state: AuthState, user: object) {
    state.user = user;
  },
  resetUser(state: AuthState) {
    state.user = {};
  },
};

const actions = {
  async login({ commit }: any, user: FirebaseUser) {// eslint-disable-line
    if (user) {
      const idToken = await getAuth().currentUser?.getIdToken();
      commit("setToken", idToken);
      commit("setUser", user);
    }
  },
  // eslint-disable-next-line
  logout({ commit }: any) {
    commit("resetToken");
    commit("resetUser");
  },
};
// eslint-disable-next-line
const getPayload = ({ token }: any) => {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
};

const getters = {
  // eslint-disable-next-line
  token(state: any) {
    return state.token;
  },
  // eslint-disable-next-line
  isLoggedIn(state: any) {
    if (!state.token) return false;
    const payload = getPayload(state);
    return payload.exp > Date.now() / 1000;
  },
  // eslint-disable-next-line
  currentUser(state: any) {
    if (!state.token) return {};
    if (!state.user) return {};
    const name = state.user.displayName;
    const email = state.user.email;
    const uid = state.user.uid;
    const isMember = state.user.roles ? true : false;
    const isCreator = state.user.creator || false;
    const isAdmin = (state.user.roles || {}).admin || isCreator;
    return { name, email, uid, isMember, isCreator, isAdmin };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
