
import { defineComponent } from "vue";

import pinsComponent from "@/components/game/pins.vue";
import frameValue from "@/components/game/frameShot.vue";

import { mapGetters } from "vuex";
import { onSnapshot, setDoc, getDoc, doc, getFirestore, Timestamp, query, where, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, onDisconnect, update, serverTimestamp } from "firebase/database";

export default defineComponent({
  name: "GameView",
  components: { pinsComponent, frameValue },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
    gameStatus(): string {
      if (this.game) {
        return "live game";
      }
      return "Loading Game";
    },
    gameDate(): string {
      if (this.game) {
        var date = this.game.created_ts.toDate();
        return date.toLocaleDateString();
      }
      return "No Date";
    },
  },
  methods: {
    getPins(index: any, frame: any): Array<any> {
      //  [ { "pins": [ 3, 2, 1 ] }, { "pins": [ 5, 9, 6, 10, 8, 4, 7 ] } ]
      if (frame.length > index) {
        if (Object.prototype.hasOwnProperty.call(frame[0], "pins")) {
          return [{ pins: frame[index].pins }];
        }
      }
      return [];
    },
    presence(gid: any) {
      // Fetch the current user's ID from Firebase Authentication.
      const auth = getAuth();
      var uid = auth.currentUser?.uid;
      if (uid != null) {
        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.

        var db = getDatabase();
        var userStatusDatabaseRef = ref(db, "/status/" + uid);
        var userStatusFirestoreRef = doc(getFirestore(), "/status/" + uid);

        // We'll create two constants which we will write to
        // the Realtime database when this device is offline
        // or online.
        var isOfflineForDatabase = {
          state: "offline",
          gid: gid,
          last_changed: serverTimestamp(),
        };

        var isOnlineForDatabase = {
          state: "online",
          gid: gid,
          last_changed: serverTimestamp(),
        };

        // Firestore uses a different server timestamp value, so we'll
        // create two more constants for Firestore state.
        var isOfflineForFirestore = {
          state: "offline",
          gid: gid,
          last_changed: Timestamp.now(),
        };

        var isOnlineForFirestore = {
          state: "online",
          gid: gid,
          last_changed: Timestamp.now(),
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.

        onValue(ref(db, ".info/connected"), function (snapshot) {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() == false) {
            return;
          }

          // If we are currently connected, then use the 'onDisconnect()'
          // method to add a set which will only trigger once this
          // client has disconnected by closing the app,
          // losing internet, or any other means.
          onDisconnect(userStatusDatabaseRef)
            .set(isOfflineForDatabase)
            .then(function () {
              // The promise returned from .onDisconnect().set() will
              // resolve as soon as the server acknowledges the onDisconnect()
              // request, NOT once we've actually disconnected:
              // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

              // We can now safely set ourselves as 'online' knowing that the
              // server will mark us as offline once we lose connection.
              update(userStatusDatabaseRef, isOnlineForDatabase);

              // We'll also add Firestore set here for when we come online.
              setDoc(userStatusFirestoreRef, isOnlineForFirestore);
            });
        });
      }
    },
  },
  data() {
    return {
      loading: false,
      gameLoading: false,
      id: "" as any,
      game: undefined as any,
      live: "🟢",
      onlineCount: 0
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.gameLoading = true;
    const docRef = doc(getFirestore(), `livegames/${this.id}`);

    var d = await getDoc(docRef);
    if (d.exists()) {
      this.presence(d.id);
    }
    this.game = d.data();
    this.gameLoading = false;
    const unsub = onSnapshot(docRef, (docSnap) => {
      this.loading = true;
      setTimeout(() => {
        this.game = docSnap.data();
        this.loading = false;
      }, 1000);
    });

    // const q = query(collection(getFirestore(), "status"), where("state", "==", "online"), where("gid", '==', this.id));
    // const unsub2 = onSnapshot(q, (docSnap) => {
    
    //   this.onlineCount = docSnap.docChanges().length
    //   docSnap.docChanges().forEach((change) => {
    //     if (change.type == 'added') {         
    //     }
    //     if (change.type == 'removed') {
    //     }
    //   }) 
    // });
    // firebase.firestore().collection('status')
    // .where('state', '==', 'online')
    // .onSnapshot(function(snapshot) {
    //     snapshot.docChanges().forEach(function(change) {
    //         if (change.type === 'added') {
    //             var msg = 'User ' + change.doc.id + ' is online.';
    //             console.log(msg);
    //             // ...
    //         }
    //         if (change.type === 'removed') {
    //             var msg = 'User ' + change.doc.id + ' is offline.';
    //             console.log(msg);
    //             // ...
    //         }
    //     });
    // });

    // } else {
    //   this.gameLoading = false
    // }

    // const docSnap = await getDoc(docRef);
    // console.log(docSnap, docSnap.exists(), docSnap.data());
  },
});
