import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"10px"} }
const _hoisted_2 = ["stroke", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("circle", {
        id: "cir1",
        cx: "5",
        cy: "5",
        r: "4",
        stroke: _ctx.status == 0 ? 'gray' : _ctx.status == 1 ? 'white' : 'white',
        "stroke-width": "2",
        fill: _ctx.status == 0 ? 'black' : _ctx.status == 1 ? 'black' : 'white'
      }, null, 8, _hoisted_2)
    ]))
  ]))
}