import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Store from "@/store";
import { signOut, getAuth } from "@/helpers/firebase";

import NotFound from "@/components/NotFound.vue";
import GameView from "@/views/Games/GameView.vue";

import Home from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeView",
    component: Home,
  },
  {
    path: "/games/:id",
    name: "GameView",
    components: {
      default: GameView,
      // navigation: Navbar
    },
  },
  {
    path: "/404",
    name: "NotFound-Component",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guards
router.beforeEach((to, from, next) => {
  // TODO: replace verification with actual firebase calls
  //https://stackoverflow.com/questions/61695595/wrap-a-vue-js-firebase-object-into-onauthstatechanged-handler
  const isLoggedIn = Store.getters["auth/isLoggedIn"];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !isLoggedIn) {
    // requires login, send to login page
    signOut(getAuth()).then(() => {
      next("/login");
    });
  } else if (!requiresAuth && isLoggedIn) {
    // allowed to go through
    next();
  } else {
    // allowed to go through
    next();
  }
});

export default router;
