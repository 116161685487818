export const DEBUG = false;

export const FIREBASE_KEYS = {
  apiKey: "AIzaSyBpiTpBnsYQLBdKcfFxJj2mEEy31fuSpBg",
  authDomain: "bowling-mate.firebaseapp.com",
  projectId: "bowling-mate",
  storageBucket: "bowling-mate.appspot.com",
  messagingSenderId: "324381501097",
  appId: "1:324381501097:web:73dcda3100e59a73bbebb8",
  measurementId: "G-F3DEJ2TWZH",
};
