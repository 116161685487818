
/* tslint:disable */
import { defineComponent } from "vue";

export default defineComponent({
  name: "frameShot",
  props: ["shots"],
  methods: {
    shotValue(shots: Array<any>) {
      if (shots.length > 0) {
        if (Object.prototype.hasOwnProperty.call(shots[0], "other")) {
          if (shots[0].other == 10) {
            return "X";
          }
          if (shots.length == 1) {
            return `${shots[0].other}`;
          }
          if (shots.length > 1) {
            if (Object.prototype.hasOwnProperty.call(shots[1], "spare")) {
              return `${shots[0].other} /`;
            }
            return `${shots[0].other} - ${shots[1].other}`;
          }
        }
        return "-";
      }
    },
  },
});
