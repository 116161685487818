import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  getIdToken,
  signInWithEmailAndPassword,
  signOut,
  getIdTokenResult,
} from "firebase/auth";
// eslint-disable-next-line
import { User as FirebaseUser } from "firebase/auth";

import { getDatabase, OnDisconnect, ref, onValue, update, serverTimestamp } from "firebase/database";
import { getFirestore, getDoc, doc, onSnapshot, Timestamp, setDoc, query, where, collection } from "firebase/firestore";

import { FIREBASE_KEYS } from "../../settings";
initializeApp(FIREBASE_KEYS);

// eslint-disable-next-line
export {
  getFirestore,
  getAuth,
  onAuthStateChanged,
  getIdToken, FirebaseUser,
  signInWithEmailAndPassword, signOut, getIdTokenResult, getDoc,
  doc, onSnapshot, getDatabase, OnDisconnect, ref, onValue, Timestamp, update, serverTimestamp,
  setDoc, query, where, collection
};
