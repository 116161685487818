
import { defineComponent } from "vue";
import pinComponent from "./pin.vue";

// status
// 0 = first round
// 1 = second round
// 2 = standing

export default defineComponent({
  name: "pinsComponent",
  components: {
    pinComponent,
  },
  props: {
    pins: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    pinStatus(number: Number) {
      if (this.pins.length > 0) {
        var p: any = this.pins[0];
        if (p.pins.includes(number)) {
          return 0;
        }
      }
      if (this.pins.length > 1) {
        var p: any = this.pins[1];
        if (p.pins.includes(number)) {
          return 1;
        }
      }
      return 2;
    },
  },
});
